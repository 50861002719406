import {
  OPEN_PARTICIPANT_MODAL,
  RESET_PARTICIPANT,
  ADD_PARTICIPANT,
  ADD_PARTICIPANT_SUCCESS,
  ADD_PARTICIPANT_ERROR,
  EDIT_PARTICIPANT_SUCCESS,
  EDIT_PARTICIPANT_ERROR,
  UPDATE_PARTICIPANT,
  UPDATE_PARTICIPANT_SUCCESS,
  UPDATE_PARTICIPANT_ERROR,
  DELETE_PARTICIPANT,
  DELETE_PARTICIPANT_SUCCESS,
  DELETE_PARTICIPANT_ERROR,
  FETCH_PARTICIPANTS,
  FETCH_PARTICIPANTS_SUCCESS,
  FETCH_PARTICIPANTS_ERROR,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_participant = {
  _id: null,
  name: null,
  phone: null,
  address: null,
  dob: null,
  gender: null,
  note: null,
  enable: false
};

const INIT_STATE = {
  pending: false,
  edit: false,
  participant_modal_open: false,
  participant: default_participant,
  participant_list: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_PARTICIPANT:
      return { ...state, pending: false, participant: { ...default_participant }, edit: false, participant_modal_open: false }

    case OPEN_PARTICIPANT_MODAL:
      return { ...state, participant_modal_open: payload }

    case FETCH_PARTICIPANTS:
    case ADD_PARTICIPANT:
      return { ...state, pending: true }

    case ADD_PARTICIPANT_SUCCESS:
      message.success('New participant added successfully');
      return { ...state, participant_list: payload.map(el => ({ key: el._id, ...el })), pending: false, participant_modal_open: false }

    case ADD_PARTICIPANT_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_PARTICIPANTS_SUCCESS:
      return { ...state, participant_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_PARTICIPANT_SUCCESS:
      console.log(payload);
      item = state.participant_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          participant: { ...payload, dob: moment(payload.dob) },
          pending: false,
          edit: true,
          participant_modal_open: true
        }
      } else {
        return {
          ...state,
          participant_list: state.participant_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          participant: { ...payload, dob: moment(payload.dob) },
          pending: false,
          edit: true,
          participant_modal_open: true
        }
      }

    case EDIT_PARTICIPANT_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case UPDATE_PARTICIPANT:
      console.log('Update participant Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_PARTICIPANT_SUCCESS:
      message.success('Participant updated successfully');
      return { ...state, participant_list: payload.map(el => ({ key: el._id, ...el })), participant: { ...default_participant }, pending: false, participant_modal_open: false, edit: false }

    case UPDATE_PARTICIPANT_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case DELETE_PARTICIPANT:
      console.log('Delete participant Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case DELETE_PARTICIPANT_SUCCESS:
      message.success('Participant deleted successfully');
      return { ...state, participant_list: payload.map(el => ({ key: el._id, ...el })), participant: { ...default_participant }, pending: false, participant_modal_open: false, edit: false }

    case DELETE_PARTICIPANT_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}
