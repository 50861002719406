import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Orders = ({match}) => (
  <Switch>
    <Route path={`${match.url}/:id`} component={asyncComponent(() => import('../../components/Orders/OrderDetail'))}/>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Orders/Orders'))}/>
  </Switch>
);

export default Orders;
