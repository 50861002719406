import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Calendar = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Calendar/VisitorCalendar'))} />
  </Switch>
);

export default Calendar;
