import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import _ from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import {
  fetchWorkshopsSuccess, addWorkshopSuccess, addWorkshopError, updateWorkshopSuccess, updateWorkshopError, deleteWorkshopSuccess, deleteWorkshopError, editWorkshopSuccess, editWorkshopError,
  exportWorkshopSuccess, exportWorkshopError, exportParticipantSuccess, exportParticipantError
} from '../actions/Workshop';
import fileDownload from 'js-file-download';

// Workshop
function* addWorkshopRequest({ payload }) {
  console.log('Add Workshop called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_workshop', payload);
    console.log(resp);
    yield put(addWorkshopSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addWorkshopError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateWorkshopRequest({ payload }) {
  console.log('Update Workshop called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_workshop', payload);
    console.log(resp);
    yield put(updateWorkshopSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateWorkshopError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteWorkshopRequest({ payload }) {
  console.log('Delete Workshop called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_workshop', payload);
    console.log(resp);
    yield put(deleteWorkshopSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteWorkshopError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchWorkshopsRequest({ payload }) {
  console.log('fetchWorkshopsRequest');

  let query = {}

  if (payload && (payload.length > 0)) {
    query = { keyword: payload }
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/workshops', query);
    yield put(fetchWorkshopsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editWorkshopRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_workshop', payload);
    yield put(editWorkshopSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editWorkshopError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportWorkshopRequest({ payload }) {
  console.log('Export Workshop called', payload);
  try {
    const resp = yield call(axios.get, '/v1/admin/export_workshop', {
      responseType: "arraybuffer",
      params: payload
    });

    if (payload.exportTarget && payload.exportTarget.includes('Download')) {
      fileDownload(resp.data, payload.filename);
    }
    yield put(exportWorkshopSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportWorkshopError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportParticipantRequest({ payload }) {
  console.log('Export Participant called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/export_workshop_participant', payload, {
      responseType: "arraybuffer",
      //params: payload
    });

    if (payload.exportTarget && payload.exportTarget.includes('Download')) {
      fileDownload(resp.data, payload.filename);
    }
    yield put(exportParticipantSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportParticipantError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchWorkshops() {
  yield takeLatest(ActionTypes.FETCH_WORKSHOPS, fetchWorkshopsRequest);
}

export function* addWorkshop() {
  yield takeLatest(ActionTypes.ADD_WORKSHOP, addWorkshopRequest);
}

export function* updateWorkshop() {
  yield takeLatest(ActionTypes.UPDATE_WORKSHOP, updateWorkshopRequest);
}

export function* deleteWorkshop() {
  yield takeLatest(ActionTypes.DELETE_WORKSHOP, deleteWorkshopRequest);
}

export function* editWorkshop() {
  yield takeLatest(ActionTypes.EDIT_WORKSHOP, editWorkshopRequest);
}

export function* exportWorkshop() {
  yield takeLatest(ActionTypes.EXPORT_WORKSHOP, exportWorkshopRequest);
}

export function* exportParticipant() {
  yield takeLatest(ActionTypes.EXPORT_PARTICIPANT, exportParticipantRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchWorkshops),
    fork(addWorkshop),
    fork(updateWorkshop),
    fork(deleteWorkshop),
    fork(editWorkshop),
    fork(exportWorkshop),
    fork(exportParticipant),
  ]);
}
