import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import _ from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError, showError } from "../actions/Common";
import { titleCase } from '../../util/common';
import {
  fetchMerchandisesSuccess, addMerchandiseSuccess, addMerchandiseError, updateMerchandiseSuccess, updateMerchandiseError, deleteMerchandiseSuccess, deleteMerchandiseError, editMerchandiseSuccess, editMerchandiseError, importMerchandisesSuccess, importMerchandisesError,
  fetchCategoriesSuccess, addCategorySuccess, addCategoryError, updateCategorySuccess, updateCategoryError, deleteCategorySuccess, deleteCategoryError, editCategorySuccess, editCategoryError,
  importInventory, importInventorySuccess, importInventoryError, fetchInventoryImportsSuccess, fetchInventoryImportsError, exportMerchandiseSuccess, exportMerchandiseError
} from '../actions/Merchandise';
import fileDownload from 'js-file-download';

// Merchandise
function* addMerchandiseRequest({ payload }) {
  console.log('Add Merchandise called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_merchandise', payload);
    console.log(resp);
    yield put(addMerchandiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addMerchandiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* importMerchandisesRequest({ payload }) {
  console.log('Import Merchandise File', payload);
  try {
    let formData = new FormData();
    formData.append('merchandisesFile', payload);
    const resp = yield call(axios.post, '/v1/admin/import_merchandises', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield put(importMerchandisesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(importMerchandisesError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateMerchandiseRequest({ payload }) {
  console.log('Update Merchandise called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_merchandise', payload);
    console.log(resp);
    yield put(updateMerchandiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateMerchandiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteMerchandiseRequest({ payload }) {
  console.log('Delete Merchandise called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_merchandise', payload);
    console.log(resp);
    yield put(deleteMerchandiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteMerchandiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchMerchandisesRequest({ payload }) {
  console.log('fetchMerchandisesRequest');

  let query = {}

  if (payload && (payload.length > 0)) {
    query = { keyword: payload }
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/merchandises', query);
    yield put(fetchMerchandisesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editMerchandiseRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_merchandise', payload);
    yield put(editMerchandiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editMerchandiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* importInventoryRequest({ payload }) {
  console.log('Import Inventory File', payload);
  try {
    let formData = new FormData();
    formData.append('inventoryFile', payload);
    const resp = yield call(axios.post, '/v1/admin/import_inventory', formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    yield put(importInventorySuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(importInventoryError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchInventoryImportsRequest() {
  console.log('Fetch Inventory Imports');
  try {
    const resp = yield call(axios.post, '/v1/admin/fetch_imports', {topic: 'inventory'});
    yield put(fetchInventoryImportsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchInventoryImportsError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportMerchandiseRequest({ payload }) {
  console.log('Export Merchandise called', payload);
  try {
    const resp = yield call(axios.get, '/v1/admin/export_merchandise', {
      responseType: "arraybuffer",
      params: payload
    });

    if (payload.exportTarget && payload.exportTarget.includes('Download')) {
      fileDownload(resp.data, payload.filename);
    }
    yield put(exportMerchandiseSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportMerchandiseError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchMerchandises() {
  yield takeLatest(ActionTypes.FETCH_MERCHANDISES, fetchMerchandisesRequest);
}

export function* addMerchandise() {
  yield takeLatest(ActionTypes.ADD_MERCHANDISE, addMerchandiseRequest);
}

export function* updateMerchandise() {
  yield takeLatest(ActionTypes.UPDATE_MERCHANDISE, updateMerchandiseRequest);
}

export function* deleteMerchandise() {
  yield takeLatest(ActionTypes.DELETE_MERCHANDISE, deleteMerchandiseRequest);
}

export function* editMerchandise() {
  yield takeLatest(ActionTypes.EDIT_MERCHANDISE, editMerchandiseRequest);
}

export function* importMerchandises() {
  yield takeLatest(ActionTypes.IMPORT_MERCHANDISES, importMerchandisesRequest);
}

export function* importInventories() {
  yield takeLatest(ActionTypes.IMPORT_INVENTORY, importInventoryRequest);
}

export function* fetchInventoryImports() {
  yield takeLatest(ActionTypes.FETCH_INVENTORY_IMPORTS, fetchInventoryImportsRequest);
}

export function* exportMerchandise() {
  yield takeLatest(ActionTypes.EXPORT_MERCHANDISE, exportMerchandiseRequest);
}

// Category
function* addCategoryRequest({ payload }) {
  console.log('Add Category called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_category', payload);
    console.log(resp);
    yield put(addCategorySuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addCategoryError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateCategoryRequest({ payload }) {
  console.log('Update Category called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_category', payload);
    console.log(resp);
    yield put(updateCategorySuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateCategoryError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteCategoryRequest({ payload }) {
  console.log('Delete Category called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_category', payload);
    console.log(resp);
    yield put(deleteCategorySuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteCategoryError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchCategoriesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/categories');
    yield put(fetchCategoriesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editCategoryRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_category', payload);
    yield put(editCategorySuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editCategoryError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchCategories() {
  yield takeLatest(ActionTypes.FETCH_CATEGORIES, fetchCategoriesRequest);
}

export function* addCategory() {
  yield takeLatest(ActionTypes.ADD_CATEGORY, addCategoryRequest);
}

export function* updateCategory() {
  yield takeLatest(ActionTypes.UPDATE_CATEGORY, updateCategoryRequest);
}

export function* deleteCategory() {
  yield takeLatest(ActionTypes.DELETE_CATEGORY, deleteCategoryRequest);
}

export function* editCategory() {
  yield takeLatest(ActionTypes.EDIT_CATEGORY, editCategoryRequest);
}


export default function* rootSaga() {
  yield all([
    fork(fetchMerchandises),
    fork(addMerchandise),
    fork(updateMerchandise),
    fork(deleteMerchandise),
    fork(editMerchandise),
    fork(fetchCategories),
    fork(addCategory),
    fork(updateCategory),
    fork(deleteCategory),
    fork(editCategory),
    fork(importMerchandises),
    fork(importInventories),
    fork(fetchInventoryImports),
    fork(exportMerchandise)
  ]);
}