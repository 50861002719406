import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchMembersSuccess, addMemberSuccess, addMemberError, editMemberSuccess, editMemberError, updateMemberSuccess, updateMemberError, deleteMemberSuccess, deleteMemberError, getOSStokenSuccess, editMemberPassesSuccess, editMemberPassesError, updateMemberTicketSuccess, updateMemberTicketError } from '../actions/Members';

function* addMemberRequest({ member }) {
  console.log('Add Member called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_member', member);
    yield put(addMemberSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addMemberError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editMemberRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_member', payload);
    yield put(editMemberSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editMemberError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateMemberRequest({ payload }) {
  console.log('Update Member called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_member', payload);
    yield put(updateMemberSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateMemberError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteMemberRequest({ payload }) {
  console.log('Delete Member called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_member', payload);
    yield put(deleteMemberSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteMemberError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchMembersRequest({ payload }) {
  let query = payload ? { search: payload } : {};

  try {
    const resp = yield call(axios.post, '/v1/admin/members', query);
    yield put(fetchMembersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editMemberPassesRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_member_tickets', payload);
    yield put(editMemberPassesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editMemberPassesError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateMemberTicketRequest({ payload }) {
  console.log('Update Member Ticket called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_member_ticket', payload);
    yield put(updateMemberTicketSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateMemberTicketError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* getOSStokenRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
    yield put(getOSStokenSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* getOSStoken() {
  yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
}

export function* fetchMembers() {
  yield takeLatest(ActionTypes.FETCH_MEMBERS, fetchMembersRequest);
}

export function* addMember() {
  yield takeLatest(ActionTypes.ADD_MEMBER, addMemberRequest);
}

export function* editMember() {
  yield takeLatest(ActionTypes.EDIT_MEMBER, editMemberRequest);
}

export function* updateMember() {
  yield takeLatest(ActionTypes.UPDATE_MEMBER, updateMemberRequest);
}

export function* deleteMember() {
  yield takeLatest(ActionTypes.DELETE_MEMBER, deleteMemberRequest);
}

export function* editMemberPasses() {
  yield takeLatest(ActionTypes.EDIT_MEMBER_PASSES, editMemberPassesRequest);
}

export function* updateMemberTicket() {
  yield takeLatest(ActionTypes.UPDATE_MEMBER_TICKET, updateMemberTicketRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchMembers),
    fork(addMember),
    fork(editMember),
    fork(updateMember),
    fork(deleteMember),
    fork(editMemberPasses),
    fork(updateMemberTicket),
    fork(getOSStoken)
  ]);
}

