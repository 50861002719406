import * as ActionTypes from '../../constants/ActionTypes';

export const fetchOrders = (orderReq) => {
  return {
    type: ActionTypes.FETCH_ORDERS,
    payload: orderReq
  }
}

export const fetchOrdersSuccess = (orders) => {
  return {
    type: ActionTypes.FETCH_ORDERS_SUCCESS,
    payload: orders
  }
}

export const fetchOrdersError = (error) => {
  return {
    type: ActionTypes.FETCH_ORDERS_ERROR,
    payload: error
  }
}

export const openOrder = (id) => {
  return {
    type: ActionTypes.OPEN_ORDER,
    payload: id
  }
}

export const resetOrder = () => {
  console.log('Reset Order');
  return {
    type: ActionTypes.RESET_ORDER
  }
}

export const addOrder = (order) => {
  return {
    type: ActionTypes.ADD_ORDER,
    order
  }
}

export const addOrderSuccess = (orders) => {
  return {
    type: ActionTypes.ADD_ORDER_SUCCESS,
    payload: orders
  }
}

export const addOrderError = (error) => {
  return {
    type: ActionTypes.ADD_ORDER_ERROR,
    payload: error
  }
}

export const processOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.PROCESS_ORDER,
    payload: order
  }
}

export const processOrderSuccess = (order) => {
  return {
    type: ActionTypes.PROCESS_ORDER_SUCCESS,
    payload: order
  }
}

export const processOrderError = (error) => {
  return {
    type: ActionTypes.PROCESS_ORDER_ERROR,
    payload: error
  }
}

export const shipOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.SHIP_ORDER,
    payload: order
  }
}

export const shipOrderSuccess = (order) => {
  return {
    type: ActionTypes.SHIP_ORDER_SUCCESS,
    payload: order
  }
}

export const shipOrderError = (error) => {
  return {
    type: ActionTypes.SHIP_ORDER_ERROR,
    payload: error
  }
}

export const cancelOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.CANCEL_ORDER,
    payload: order
  }
}

export const cancelOrderSuccess = (order) => {
  return {
    type: ActionTypes.CANCEL_ORDER_SUCCESS,
    payload: order
  }
}

export const cancelOrderError = (error) => {
  return {
    type: ActionTypes.CANCEL_ORDER_ERROR,
    payload: error
  }
}

export const refundOrder = (order) => {
  console.log(order);
  return {
    type: ActionTypes.REFUND_ORDER,
    payload: order
  }
}

export const refundOrderSuccess = (order) => {
  return {
    type: ActionTypes.REFUND_ORDER_SUCCESS,
    payload: order
  }
}

export const refundOrderError = (error) => {
  return {
    type: ActionTypes.REFUND_ORDER_ERROR,
    payload: error
  }
}

export const addOrderNote = (note) => {
  console.log(note);
  return {
    type: ActionTypes.ADD_ORDER_NOTE,
    payload: note
  }
}

export const addOrderNoteSuccess = (order) => {
  return {
    type: ActionTypes.ADD_ORDER_NOTE_SUCCESS,
    payload: order
  }
}

export const addOrderNoteError = (error) => {
  return {
    type: ActionTypes.ADD_ORDER_NOTE_ERROR,
    payload: error
  }
}

export const exportOrderReport = (period) => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT,
    payload: period
  }
}

export const exportOrderReportSuccess = () => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT_SUCCESS,
  }
}

export const exportOrderReportError = (error) => {
  return {
    type: ActionTypes.EXPORT_ORDER_REPORT_ERROR,
    payload: error
  }
}

export const exportDailyReport = (period) => {
  return {
    type: ActionTypes.EXPORT_DAILY_REPORT,
    payload: period
  }
}

export const exportDailyReportSuccess = () => {
  return {
    type: ActionTypes.EXPORT_DAILY_REPORT_SUCCESS,
  }
}

export const exportDailyReportError = (error) => {
  return {
    type: ActionTypes.EXPORT_DAILY_REPORT_ERROR,
    payload: error
  }
}

export const resendInvoice = (id) => {
  return {
    type: ActionTypes.RESEND_INVOICE,
    payload: id
  }
}

export const resendInvoiceSuccess = () => {
  return {
    type: ActionTypes.RESEND_INVOICE_SUCCESS,
  }
}

export const resendInvoiceError = (error) => {
  return {
    type: ActionTypes.RESEND_INVOICE_ERROR,
    payload: error
  }
}

export const changePaymentMethods = (update) => {
  return {
    type: ActionTypes.CHANGE_PAYMENT_METHODS,
    payload: update
  }
}

export const changePaymentMethodsSuccess = (order) => {
  return {
    type: ActionTypes.CHANGE_PAYMENT_METHODS_SUCCESS,
    payload: order
  }
}

export const changePaymentMethodsError = (error) => {
  return {
    type: ActionTypes.CHANGE_PAYMENT_METHODS_ERROR,
    payload: error
  }
}

export const emailUpdate = (updateEmail) => {
  return {
    type: ActionTypes.UPDATE_EMAIL,
    payload: updateEmail
  }
}

export const emailUpdateSuccess = (order) => {
  return {
    type: ActionTypes.UPDATE_EMAIL_SUCCESS,
    payload: order
  }
}

export const emailUpdateError = (error) => {
  return {
    type: ActionTypes.UPDATE_EMAIL_ERROR,
    payload: error
  }
}

export const transID = (transID) => {
  console.log(transID)
  return {
    type: ActionTypes.UPDATE_TRANS_ID,
    payload: transID
  }
}

export const transIDUpdateSuccess = (order) => {
  return {
    type: ActionTypes.UPDATE_TRANS_ID_SUCCESS,
    payload: order
  }
}

export const transIDUpdateError = (error) => {
  return {
    type: ActionTypes.UPDATE_TRANS_ID_ERROR,
    payload: error
  }
}