import {
  OPEN_COUPON_MODAL,
  RESET_COUPON,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_ERROR,
  RETIRE_COUPON,
  RETIRE_COUPON_SUCCESS,
  RETIRE_COUPON_ERROR,
  FETCH_COUPONS_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';

const default_coupon = {
  _id: null,
  desc: null,
  campaign_start: null,
  campaign_end: null,
  discount_type: null,
  discount_products: [],
  discount_value: null,
  discount_cap: null,
  discount_event: {
    type: null,
    date_range: []
  },
  min_purchase: null,
  limit_per_user: null,
  quantity: null,
  enable: false,
  claimants: [],
};

const INIT_STATE = {
  pending: false,
  edit: false,
  coupon_modal_open: false,
  coupon: default_coupon,
  coupon_list: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_COUPON:
      return { ...state, pending: false, coupon: { ...default_coupon }, edit: false, coupon_modal_open: false }

    case OPEN_COUPON_MODAL:
      return { ...state, coupon_modal_open: payload }

    case ADD_COUPON:
      return { ...state, pending: true }

    case ADD_COUPON_SUCCESS:
      message.success('New coupon added successfully');
      return { ...state, coupon_list: payload.map(el => ({ key: el._id, ...el })), pending: false, coupon_modal_open: false }

    case ADD_COUPON_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case FETCH_COUPONS_SUCCESS:
      return { ...state, coupon_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case RETIRE_COUPON:
      return { ...state, pending: true }

    case RETIRE_COUPON_SUCCESS:
      message.success('Coupon retired successfully');
      return { ...state, coupon_list: payload.map(el => ({ key: el._id, ...el })), coupon: { ...default_coupon }, pending: false, coupon_modal_open: false, edit: false }

    case RETIRE_COUPON_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    default:
      return state;
  }
}
