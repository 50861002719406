import * as ActionTypes from '../../constants/ActionTypes';

export const fetchUsers = () => {
  return {
    type: ActionTypes.FETCH_USERS
  }
}

export const fetchUsersSuccess = (users) => {
  return {
    type: ActionTypes.FETCH_USERS_SUCCESS,
    payload: users
  }
}

export const fetchUsersError = () => {
  return {
    type: ActionTypes.FETCH_USERS_ERROR,
  }
}

export const openUserModal = (state) => {
  return {
    type: ActionTypes.OPEN_USER_MODAL,
    payload: state
  }
}

export const resetUser = () => {
  console.log('Reset User');
  return {
    type: ActionTypes.RESET_USER
  }
}

export const addUser = (user) => {
  return {
    type: ActionTypes.ADD_USER,
    user
  }
}

export const addUserSuccess = (users) => {
  return {
    type: ActionTypes.ADD_USER_SUCCESS,
    payload: users
  }
}

export const addUserError = (error) => {
  return {
    type: ActionTypes.ADD_USER_ERROR,
    payload: error
  }
}

export const editUser = (query) => {
  return {
    type: ActionTypes.EDIT_USER,
    payload: query
  }
}

export const editUserSuccess = (user) => {
  return {
    type: ActionTypes.EDIT_USER_SUCCESS,
    payload: user
  }
}

export const editUserError = (error) => {
  return {
    type: ActionTypes.EDIT_USER_ERROR,
    payload: error
  }
}

export const updateUser = (user) => {
  console.log(user);
  return {
    type: ActionTypes.UPDATE_USER,
    payload: user
  }
}

export const updateUserSuccess = (users) => {
  return {
    type: ActionTypes.UPDATE_USER_SUCCESS,
    payload: users
  }
}

export const updateUserError = (message) => {
  return {
    type: ActionTypes.UPDATE_USER_ERROR,
    payload: message
  }
}

export const deleteUser = (user) => {
  console.log(user);
  return {
    type: ActionTypes.DELETE_USER,
    payload: user
  }
}

export const deleteUserSuccess = (users) => {
  return {
    type: ActionTypes.DELETE_USER_SUCCESS,
    payload: users
  }
}

export const deleteUserError = (message) => {
  return {
    type: ActionTypes.DELETE_USER_ERROR,
    payload: message
  }
}

export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}