import {
  PROCESS_ORDER,
  PROCESS_ORDER_SUCCESS,
  PROCESS_ORDER_ERROR,
  SHIP_ORDER,
  SHIP_ORDER_SUCCESS,
  SHIP_ORDER_ERROR,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  REFUND_ORDER,
  REFUND_ORDER_SUCCESS,
  REFUND_ORDER_ERROR,
  OPEN_ORDER,
  FETCH_ORDERS,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_ERROR,
  ADD_ORDER_NOTE,
  ADD_ORDER_NOTE_SUCCESS,
  ADD_ORDER_NOTE_ERROR,
  EXPORT_ORDER_REPORT,
  EXPORT_ORDER_REPORT_SUCCESS,
  EXPORT_ORDER_REPORT_ERROR,
  EXPORT_DAILY_REPORT,
  EXPORT_DAILY_REPORT_SUCCESS,
  EXPORT_DAILY_REPORT_ERROR,
  RESEND_INVOICE,
  RESEND_INVOICE_SUCCESS,
  RESEND_INVOICE_ERROR,
  CHANGE_PAYMENT_METHODS,
  CHANGE_PAYMENT_METHODS_SUCCESS,
  CHANGE_PAYMENT_METHODS_ERROR,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_ERROR,
  UPDATE_TRANS_ID,
  UPDATE_TRANS_ID_SUCCESS,
  UPDATE_TRANS_ID_ERROR
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';

const INIT_STATE = {
  pending: false,
  edit: false,
  order_modal_open: false,
  order: null,
  order_list: [],
  order_count: 0
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case OPEN_ORDER:
      console.log(state.order_list);
      return { ...state, order: state.order_list.find(order => order._id == payload) }

    case FETCH_ORDERS:
      return { ...state, pending: true }

    case FETCH_ORDERS_SUCCESS:
      return { ...state, order_list: payload.orders.map(el => ({ key: el._id, ...el })), order_count: payload.count, pending: false }

    case FETCH_ORDERS_ERROR:
      message.error('Failed to retrieve orders');
      return { ...state, order_list: [], pending: false }

    case ADD_ORDER_NOTE:
    case PROCESS_ORDER:
    case SHIP_ORDER:
    case CANCEL_ORDER:
    case REFUND_ORDER:
    case EXPORT_ORDER_REPORT:
    case EXPORT_DAILY_REPORT:
    case RESEND_INVOICE:
    case CHANGE_PAYMENT_METHODS:
    case UPDATE_EMAIL:
    case UPDATE_TRANS_ID:
      return { ...state, pending: true }

    case ADD_ORDER_NOTE_SUCCESS:
    case PROCESS_ORDER_SUCCESS:
    case SHIP_ORDER_SUCCESS:
    case CANCEL_ORDER_SUCCESS:
    case REFUND_ORDER_SUCCESS:
    case CHANGE_PAYMENT_METHODS_SUCCESS:
    case UPDATE_EMAIL_SUCCESS:
    case UPDATE_TRANS_ID_SUCCESS:
      message.success('Order updated successfully');
      let order_list = state.order_list.map(el => {
        if (el._id === payload._id) return ({ key: payload._id, ...payload })

        return el;
      });
      let order = order_list.find(order => order._id === payload._id);

      return { ...state, order_list, order, pending: false }

    case ADD_ORDER_NOTE_ERROR:
    case PROCESS_ORDER_ERROR:
    case SHIP_ORDER_ERROR:
    case CANCEL_ORDER_ERROR:
    case REFUND_ORDER_ERROR:
    case EXPORT_ORDER_REPORT_ERROR:
    case EXPORT_DAILY_REPORT_ERROR:
    case RESEND_INVOICE_ERROR:
    case CHANGE_PAYMENT_METHODS_ERROR:
    case UPDATE_EMAIL_ERROR:
    case UPDATE_TRANS_ID_ERROR:
      message.error(payload);
      return { ...state, pending: false }

    case RESEND_INVOICE_SUCCESS:
      message.success('Invoice resend successfully');
      return { ...state, pending: false }

    case EXPORT_ORDER_REPORT_SUCCESS:
    case EXPORT_DAILY_REPORT_SUCCESS:
      message.success('Report exported successfully');
      return { ...state, pending: false }

    // case ADD_ORDER_NOTE:
    //   return { ...state, pending: true }

    // case ADD_ORDER_NOTE_SUCCESS:
    //   message.success('Successfully added note');
    //   return { ...state, pending: false }

    // case ADD_ORDER_NOTE_ERROR:
    //   message.error('Failed to add note');
    //   return { ...state, pending: false }

    default:
      return state;
  }
}
