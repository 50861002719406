import * as ActionTypes from '../../constants/ActionTypes';

export const fetchSales = (saleReq) => {
  return {
    type: ActionTypes.FETCH_SALES,
    payload: saleReq
  }
}

export const fetchSalesSuccess = (sales) => {
  return {
    type: ActionTypes.FETCH_SALES_SUCCESS,
    payload: sales
  }
}

export const fetchSalesError = (error) => {
  return {
    type: ActionTypes.FETCH_SALES_ERROR,
    payload: error
  }
}

export const fetchSalesReport = (saleReportReq) => {
  return {
    type: ActionTypes.FETCH_SALES_REPORT,
    payload: saleReportReq
  }
}

export const fetchSalesReportSuccess = (salesReport) => {
  return {
    type: ActionTypes.FETCH_SALES_REPORT_SUCCESS,
    payload: salesReport
  }
}

export const fetchSalesReportError = (error) => {
  return {
    type: ActionTypes.FETCH_SALES_REPORT_ERROR,
    payload: error
  }
}
