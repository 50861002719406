import * as ActionTypes from '../../constants/ActionTypes';

// Workshops
export const fetchWorkshops = (keyword) => {
  return {
    type: ActionTypes.FETCH_WORKSHOPS,
    payload: keyword
  }
}

export const fetchWorkshopsSuccess = (workshops) => {
  return {
    type: ActionTypes.FETCH_WORKSHOPS_SUCCESS,
    payload: workshops
  }
}

export const fetchWorkshopError = () => {
  return {
    type: ActionTypes.FETCH_WORKSHOPS_ERROR,
  }
}

export const editWorkshop = (query) => {
  return {
    type: ActionTypes.EDIT_WORKSHOP,
    payload: query
  }
}

export const editWorkshopSuccess = (workshop) => {
  return {
    type: ActionTypes.EDIT_WORKSHOP_SUCCESS,
    payload: workshop
  }
}

export const editWorkshopError = () => {
  return {
    type: ActionTypes.EDIT_WORKSHOP_ERROR,
  }
}

export const addWorkshop = (workshop) => {
  console.log(workshop);
  return {
    type: ActionTypes.ADD_WORKSHOP,
    payload: workshop
  }
}

export const addWorkshopSuccess = (workshops) => {
  return {
    type: ActionTypes.ADD_WORKSHOP_SUCCESS,
    payload: workshops
  }
}

export const addWorkshopError = (message) => {
  return {
    type: ActionTypes.ADD_WORKSHOP_ERROR,
    payload: message
  }
}

export const updateWorkshop = (workshop) => {
  console.log(workshop);
  return {
    type: ActionTypes.UPDATE_WORKSHOP,
    payload: workshop
  }
}

export const updateWorkshopSuccess = (workshops) => {
  return {
    type: ActionTypes.UPDATE_WORKSHOP_SUCCESS,
    payload: workshops
  }
}

export const updateWorkshopError = (message) => {
  return {
    type: ActionTypes.UPDATE_WORKSHOP_ERROR,
    payload: message
  }
}

export const deleteWorkshop = (workshop) => {
  console.log(workshop);
  return {
    type: ActionTypes.DELETE_WORKSHOP,
    payload: workshop
  }
}

export const deleteWorkshopSuccess = (workshops) => {
  return {
    type: ActionTypes.DELETE_WORKSHOP_SUCCESS,
    payload: workshops
  }
}

export const deleteWorkshopError = (message) => {
  return {
    type: ActionTypes.DELETE_WORKSHOP_ERROR,
    payload: message
  }
}

export const exportWorkshop = (params) => {
  return {
    type: ActionTypes.EXPORT_WORKSHOP,
    payload: params
  }
}

export const exportWorkshopSuccess = () => {
  return {
    type: ActionTypes.EXPORT_WORKSHOP_SUCCESS
  }
}

export const exportWorkshopError = (error) => {
  return {
    type: ActionTypes.EXPORT_WORKSHOP_ERROR,
    payload: error
  }
}

export const resetWorkshop = () => {
  return {
    type: ActionTypes.RESET_WORKSHOP
  }
}

export const openWorkshopModal = (state) => {
  return {
    type: ActionTypes.OPEN_WORKSHOP_MODAL,
    payload: state
  }
}

export const openCompetitionModal = (state) => {
  return {
    type: ActionTypes.OPEN_COMPETITION_MODAL,
    payload: state
  }
}

export const exportParticipant = (params) => {
  return {
    type: ActionTypes.EXPORT_PARTICIPANT,
    payload: params
  }
}

export const exportParticipantSuccess = () => {
  return {
    type: ActionTypes.EXPORT_PARTICIPANT_SUCCESS
  }
}

export const exportParticipantError = (error) => {
  return {
    type: ActionTypes.EXPORT_PARTICIPANT_ERROR,
    payload: error
  }
}