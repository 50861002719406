import {
  OPEN_USER_MODAL,
  RESET_USER,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_ERROR,
  EDIT_USER_SUCCESS,
  EDIT_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_ERROR,
  GET_OSS_TOKEN_SUCCESS,
} from '../../constants/ActionTypes';
import { message } from 'antd';
import _ from 'lodash';
import config from '../../config.json';

const OSS = require('ali-oss');

const default_user = {
  _id: null,
  name: null,
  mobile: null,
  phone: null,
  role: [],
  note: null,
  enable: false
};

const INIT_STATE = {
  pending: false,
  edit: false,
  user_modal_open: false,
  user: default_user,
  user_list: [],
  oss_cred: null,
  OSSclient: null,
}

export default (state = INIT_STATE, { type, payload }) => {
  let item;

  switch (type) {
    case RESET_USER:
      return { ...state, pending: false, user: { ...default_user }, edit: false, user_modal_open: false }

    case OPEN_USER_MODAL:
      return { ...state, user_modal_open: payload }

    case FETCH_USERS:
    case ADD_USER:
      return { ...state, pending: true }

    case ADD_USER_SUCCESS:
      message.success('New user added successfully');
      return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), user: { ...default_user }, user_modal_open: false, pending: false }

    case ADD_USER_ERROR:
      message.error(payload.data);
      return { ...state, pending: false }

    case FETCH_USERS_SUCCESS:
      return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), pending: false }

    case EDIT_USER_SUCCESS:
      console.log(payload);
      item = state.user_list.find((el) => el._id === payload._id);
      payload.key = payload._id;
      if (_.isEqual(item, payload)) {
        return {
          ...state,
          user: { ...payload },
          pending: false,
          edit: true,
          user_modal_open: true
        }
      } else {
        return {
          ...state,
          user_list: state.user_list.map(el => {
            if (el._id === payload._id) {
              return ({ key: el._id, ...payload })
            } else {
              return ({ key: el._id, ...el })
            }
          }),
          user: { ...payload },
          pending: false,
          edit: true,
          user_modal_open: true
        }
      }

    case EDIT_USER_ERROR:
      message.error(payload.data);
      return { ...state, pending: false }

    case UPDATE_USER:
      console.log('Update user Reducer');
      console.log(payload);
      return { ...state, pending: true }

    case UPDATE_USER_SUCCESS:
      message.success('User updated successfully');
      return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), user: { ...default_user }, pending: false, user_modal_open: false, edit: false }

    case UPDATE_USER_ERROR:
      message.error(payload.data);
      return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), user: { ...default_user }, user_modal_open: false, pending: false }

    // case DELETE_USER:
    //   console.log('Delete user Reducer');
    //   console.log(payload);
    //   return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), user: { ...default_user }, user_modal_open: false, pending: false }

    case DELETE_USER_SUCCESS:
      message.success('User deleted successfully');
      return { ...state, user_list: payload.map(el => ({ key: el._id, ...el })), user: { ...default_user }, pending: false, user_modal_open: false, edit: false }

    case DELETE_USER_ERROR:
      message.error(payload.data);
      return { ...state, pending: false }

    case GET_OSS_TOKEN_SUCCESS:
      let client = new OSS({
        region: config.oss.region,
        bucket: config.oss.bucket,
        accessKeyId: payload.AccessKeyId,
        accessKeySecret: payload.AccessKeySecret,
        stsToken: payload.SecurityToken
      });
      return { ...state, oss_cred: payload, OSSclient: client };

    default:
      return state;
  }
}