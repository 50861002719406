import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Sales = ({ match }) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Sales/Sales'))}/>
  </Switch>
);

export default Sales;
