import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchParticipantsSuccess, addParticipantSuccess, addParticipantError, editParticipantSuccess, editParticipantError, updateParticipantSuccess, updateParticipantError, deleteParticipantSuccess, deleteParticipantError, getOSStokenSuccess } from '../actions/Participants';

function* addParticipantRequest({ payload }) {
  console.log('Add Participant called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_participant', payload);
    yield put(addParticipantSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addParticipantError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editParticipantRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_participant', payload);
    yield put(editParticipantSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editParticipantError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateParticipantRequest({ payload }) {
  console.log('Update Participant called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_participant', payload);
    yield put(updateParticipantSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateParticipantError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteParticipantRequest({ payload }) {
  console.log('Delete Participant called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_participant', payload);
    yield put(deleteParticipantSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteParticipantError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchParticipantsRequest({ payload }) {
  let query = payload ? { search: payload } : {};

  try {
    const resp = yield call(axios.post, '/v1/admin/participants', query);
    yield put(fetchParticipantsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* getOSStokenRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
    yield put(getOSStokenSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* getOSStoken() {
  yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
}

export function* fetchParticipants() {
  yield takeLatest(ActionTypes.FETCH_PARTICIPANTS, fetchParticipantsRequest);
}

export function* addParticipant() {
  yield takeLatest(ActionTypes.ADD_PARTICIPANT, addParticipantRequest);
}

export function* editParticipant() {
  yield takeLatest(ActionTypes.EDIT_PARTICIPANT, editParticipantRequest);
}

export function* updateParticipant() {
  yield takeLatest(ActionTypes.UPDATE_PARTICIPANT, updateParticipantRequest);
}

export function* deleteParticipant() {
  yield takeLatest(ActionTypes.DELETE_PARTICIPANT, deleteParticipantRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchParticipants),
    fork(addParticipant),
    fork(editParticipant),
    fork(updateParticipant),
    fork(deleteParticipant),
    fork(getOSStoken)
  ]);
}

