import * as ActionTypes from '../../constants/ActionTypes';

export const clearTicket = () => {
  return {
    type: ActionTypes.CLEAR_TICKET
  }
}

export const getTicket = (query) => {
  return {
    type: ActionTypes.GET_TICKET,
    payload: query
  }
}

export const getTicketSuccess = (ticket) => {
  return {
    type: ActionTypes.GET_TICKET_SUCCESS,
    payload: ticket
  }
}

export const getTicketError = (error) => {
  return {
    type: ActionTypes.GET_TICKET_ERROR,
    payload: error
  }
}

export const fetchTickets = (query) => {
  return {
    type: ActionTypes.FETCH_TICKETS,
    payload: query
  }
}

export const fetchTicketsSuccess = (ticket) => {
  return {
    type: ActionTypes.FETCH_TICKETS_SUCCESS,
    payload: ticket
  }
}

export const fetchTicketsError = (error) => {
  return {
    type: ActionTypes.FETCH_TICKETS_ERROR,
    payload: error
  }
}

export const retrievePhoto = (query) => {
  return {
    type: ActionTypes.RETRIEVE_PHOTO,
    payload: query
  }
}

export const retrievePhotoSuccess = (photo) => {
  return {
    type: ActionTypes.RETRIEVE_PHOTO_SUCCESS,
    payload: photo
  }
}

export const retrievePhotoError = (error) => {
  return {
    type: ActionTypes.RETRIEVE_PHOTO_ERROR,
    payload: error
  }
}

export const clearPhoto = () => {
  return {
    type: ActionTypes.CLEAR_PHOTO
  }
}
