import * as ActionTypes from '../../constants/ActionTypes';

export const fetchBanners = () => {
  return {
    type: ActionTypes.FETCH_BANNERS
  }
}

export const fetchBannersSuccess = (banners) => {
  return {
    type: ActionTypes.FETCH_BANNERS_SUCCESS,
    payload: banners
  }
}

export const fetchBannersError = (error) => {
  return {
    type: ActionTypes.FETCH_BANNERS_ERROR,
    payload: error
  }
}

export const openBannerModal = (state) => {
  return {
    type: ActionTypes.OPEN_BANNER_MODAL,
    payload: state
  }
}

export const resetBanner = () => {
  console.log('Reset Banner');
  return {
    type: ActionTypes.RESET_BANNER
  }
}

export const addBanner = (banner) => {
  return {
    type: ActionTypes.ADD_BANNER,
    banner
  }
}

export const addBannerSuccess = (banners) => {
  return {
    type: ActionTypes.ADD_BANNER_SUCCESS,
    payload: banners
  }
}

export const addBannerError = (error) => {
  return {
    type: ActionTypes.ADD_BANNER_ERROR,
    payload: error
  }
}

export const editBanner = (query) => {
  return {
    type: ActionTypes.EDIT_BANNER,
    payload: query
  }
}

export const editBannerSuccess = (banner) => {
  return {
    type: ActionTypes.EDIT_BANNER_SUCCESS,
    payload: banner
  }
}

export const editBannerError = (error) => {
  return {
    type: ActionTypes.EDIT_BANNER_ERROR,
    payload: error
  }
}

export const updateBanner = (banner) => {
  console.log(banner);
  return {
    type: ActionTypes.UPDATE_BANNER,
    payload: banner
  }
}

export const updateBannerSuccess = (banners) => {
  return {
    type: ActionTypes.UPDATE_BANNER_SUCCESS,
    payload: banners
  }
}

export const updateBannerError = (error) => {
  return {
    type: ActionTypes.UPDATE_BANNER_ERROR,
    payload: error
  }
}

export const deleteBanner = (banner) => {
  return {
    type: ActionTypes.DELETE_BANNER,
    payload: banner
  }
}

export const deleteBannerSuccess = (banners) => {
  return {
    type: ActionTypes.DELETE_BANNER_SUCCESS,
    payload: banners
  }
}

export const deleteBannerError = (error) => {
  return {
    type: ActionTypes.DELETE_BANNER_ERROR,
    payload: error
  }
}

export const updateBannerOrders = (orders) => {
  return {
    type: ActionTypes.UPDATE_BANNER_ORDERS,
    payload: orders
  }
}

export const updateBannerOrdersSuccess = () => {
  return {
    type: ActionTypes.UPDATE_BANNER_ORDERS_SUCCESS,
  }
}

export const updateBannerOrdersError = (error) => {
  return {
    type: ActionTypes.UPDATE_BANNER_ORDERS_ERROR,
    payload: error
  }
}


export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}