import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Gyms = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Gyms/GymTable'))}/>
  </Switch>
);

export default Gyms;
