import * as ActionTypes from '../../constants/ActionTypes';

export const fetchParticipants = (searchStr) => {
  return {
    type: ActionTypes.FETCH_PARTICIPANTS,
    payload: searchStr
  }
}

export const fetchParticipantsSuccess = (participants) => {
  return {
    type: ActionTypes.FETCH_PARTICIPANTS_SUCCESS,
    payload: participants
  }
}

export const fetchParticipantsError = (error) => {
  return {
    type: ActionTypes.FETCH_PARTICIPANTS_ERROR,
    payload: error
  }
}

export const openParticipantModal = (state) => {
  return {
    type: ActionTypes.OPEN_PARTICIPANT_MODAL,
    payload: state
  }
}

export const resetParticipant = () => {
  console.log('Reset Participant');
  return {
    type: ActionTypes.RESET_PARTICIPANT
  }
}

export const addParticipant = (participant) => {
  return {
    type: ActionTypes.ADD_PARTICIPANT,
    payload: participant
  }
}

export const addParticipantSuccess = (participants) => {
  return {
    type: ActionTypes.ADD_PARTICIPANT_SUCCESS,
    payload: participants
  }
}

export const addParticipantError = (error) => {
  return {
    type: ActionTypes.ADD_PARTICIPANT_ERROR,
    payload: error
  }
}

export const editParticipant = (query) => {
  return {
    type: ActionTypes.EDIT_PARTICIPANT,
    payload: query
  }
}

export const editParticipantSuccess = (participant) => {
  return {
    type: ActionTypes.EDIT_PARTICIPANT_SUCCESS,
    payload: participant
  }
}

export const editParticipantError = (error) => {
  return {
    type: ActionTypes.EDIT_PARTICIPANT_ERROR,
    payload: error
  }
}

export const updateParticipant = (participant) => {
  console.log(participant);
  return {
    type: ActionTypes.UPDATE_PARTICIPANT,
    payload: participant
  }
}

export const updateParticipantSuccess = (participants) => {
  return {
    type: ActionTypes.UPDATE_PARTICIPANT_SUCCESS,
    payload: participants
  }
}

export const updateParticipantError = (error) => {
  return {
    type: ActionTypes.UPDATE_PARTICIPANT_ERROR,
    payload: error
  }
}

export const deleteParticipant = (participant) => {
  console.log(participant);
  return {
    type: ActionTypes.DELETE_PARTICIPANT,
    payload: participant
  }
}

export const deleteParticipantSuccess = (participants) => {
  return {
    type: ActionTypes.DELETE_PARTICIPANT_SUCCESS,
    payload: participants
  }
}

export const deleteParticipantError = (error) => {
  return {
    type: ActionTypes.DELETE_PARTICIPANT_ERROR,
    payload: error
  }
}

export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}