import * as ActionTypes from '../../constants/ActionTypes';

// Passes
export const fetchPasses = (keyword) => {
  return {
    type: ActionTypes.FETCH_PASSES,
    payload: keyword
  }
}

export const fetchPassesSuccess = (passes) => {
  return {
    type: ActionTypes.FETCH_PASSES_SUCCESS,
    payload: passes
  }
}

export const fetchPassesError = () => {
  return {
    type: ActionTypes.FETCH_PASSES_ERROR,
  }
}

export const editPass = (query) => {
  return {
    type: ActionTypes.EDIT_PASS,
    payload: query
  }
}

export const editPassSuccess = (pass) => {
  return {
    type: ActionTypes.EDIT_PASS_SUCCESS,
    payload: pass
  }
}

export const editPassError = () => {
  return {
    type: ActionTypes.EDIT_PASS_ERROR,
  }
}

export const addPass = (pass) => {
  console.log(pass);
  return {
    type: ActionTypes.ADD_PASS,
    payload: pass
  }
}

export const addPassSuccess = (passes) => {
  return {
    type: ActionTypes.ADD_PASS_SUCCESS,
    payload: passes
  }
}

export const addPassError = (message) => {
  return {
    type: ActionTypes.ADD_PASS_ERROR,
    payload: message
  }
}

export const updatePass = (pass) => {
  console.log(pass);
  return {
    type: ActionTypes.UPDATE_PASS,
    payload: pass
  }
}

export const updatePassSuccess = (passes) => {
  return {
    type: ActionTypes.UPDATE_PASS_SUCCESS,
    payload: passes
  }
}

export const updatePassError = (message) => {
  return {
    type: ActionTypes.UPDATE_PASS_ERROR,
    payload: message
  }
}

export const deletePass = (pass) => {
  console.log(pass);
  return {
    type: ActionTypes.DELETE_PASS,
    payload: pass
  }
}

export const deletePassSuccess = (passes) => {
  return {
    type: ActionTypes.DELETE_PASS_SUCCESS,
    payload: passes
  }
}

export const deletePassError = (message) => {
  return {
    type: ActionTypes.DELETE_PASS_ERROR,
    payload: message
  }
}

export const getPassSetup = () => {
  return {
    type: ActionTypes.GET_PASS_SETUP
  }
}

export const getPassSetupSuccess = (setup) => {
  return {
    type: ActionTypes.GET_PASS_SETUP_SUCCESS,
    payload: setup
  }
}

export const getPassSetupError = () => {
  return {
    type: ActionTypes.GET_PASS_SETUP_ERROR,
  }
}

export const exportPass = (params) => {
  return {
    type: ActionTypes.EXPORT_PASS,
    payload: params
  }
}

export const exportPassSuccess = () => {
  return {
    type: ActionTypes.EXPORT_PASS_SUCCESS
  }
}

export const exportPassError = (error) => {
  return {
    type: ActionTypes.EXPORT_PASS_ERROR,
    payload: error
  }
}
export const resetPass = () => {
  return {
    type: ActionTypes.RESET_PASS
  }
}

export const openPassModal = (state) => {
  return {
    type: ActionTypes.OPEN_PASS_MODAL,
    payload: state
  }
}

// Group
export const fetchGroups = () => {
  return {
    type: ActionTypes.FETCH_GROUPS
  }
}

export const fetchGroupsSuccess = (groups) => {
  return {
    type: ActionTypes.FETCH_GROUPS_SUCCESS,
    payload: groups
  }
}

export const fetchGroupsError = () => {
  return {
    type: ActionTypes.FETCH_GROUPS_ERROR,
  }
}

export const editGroup = (query) => {
  return {
    type: ActionTypes.EDIT_GROUP,
    payload: query
  }
}

export const editGroupSuccess = (group) => {
  return {
    type: ActionTypes.EDIT_GROUP_SUCCESS,
    payload: group
  }
}

export const editGroupError = () => {
  return {
    type: ActionTypes.EDIT_GROUP_ERROR,
  }
}

export const addGroup = (group) => {
  console.log(group);
  return {
    type: ActionTypes.ADD_GROUP,
    payload: group
  }
}

export const addGroupSuccess = (groups) => {
  return {
    type: ActionTypes.ADD_GROUP_SUCCESS,
    payload: groups
  }
}

export const addGroupError = (message) => {
  return {
    type: ActionTypes.ADD_GROUP_ERROR,
    payload: message
  }
}

export const updateGroup = (group) => {
  console.log(group);
  return {
    type: ActionTypes.UPDATE_GROUP,
    payload: group
  }
}

export const updateGroupSuccess = (groups) => {
  return {
    type: ActionTypes.UPDATE_GROUP_SUCCESS,
    payload: groups
  }
}

export const updateGroupError = (message) => {
  return {
    type: ActionTypes.UPDATE_GROUP_ERROR,
    payload: message
  }
}

export const deleteGroup = (group) => {
  console.log(group);
  return {
    type: ActionTypes.DELETE_GROUP,
    payload: group
  }
}

export const deleteGroupSuccess = (groups) => {
  return {
    type: ActionTypes.DELETE_GROUP_SUCCESS,
    payload: groups
  }
}

export const deleteGroupError = (message) => {
  return {
    type: ActionTypes.DELETE_GROUP_ERROR,
    payload: message
  }
}

export const resetGroup = () => {
  return {
    type: ActionTypes.RESET_GROUP
  }
}

export const openGroupModal = (state) => {
  return {
    type: ActionTypes.OPEN_GROUP_MODAL,
    payload: state
  }
}

export const fetchPassCategories = () => {
  return {
    type: ActionTypes.FETCH_PASS_CATEGORIES
  }
}

export const fetchPassCategoriesSuccess = (categories) => {
  return {
    type: ActionTypes.FETCH_PASS_CATEGORIES_SUCCESS,
    payload: categories
  }
}
