import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchCouponsSuccess, addCouponSuccess, addCouponError, retireCouponSuccess, retireCouponError } from '../actions/Coupons';

function* addCouponRequest({ coupon }) {
  console.log('Add Coupon called', coupon);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_coupon', coupon);
    yield put(addCouponSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addCouponError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* retireCouponRequest({ payload }) {
  console.log('Update Coupon called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/retire_coupon', payload);
    console.log(resp);
    yield put(retireCouponSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(retireCouponError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchCouponsRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/coupons');
    yield put(fetchCouponsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchCoupons() {
  yield takeLatest(ActionTypes.FETCH_COUPONS, fetchCouponsRequest);
}

export function* addCoupon() {
  yield takeLatest(ActionTypes.ADD_COUPON, addCouponRequest);
}

export function* retireCoupon() {
  yield takeLatest(ActionTypes.RETIRE_COUPON, retireCouponRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCoupons),
    fork(addCoupon),
    fork(retireCoupon)
  ]);
}

