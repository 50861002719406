import * as ActionTypes from '../../constants/ActionTypes';

// Merchandises
export const fetchMerchandises = (keyword) => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES,
    payload: keyword
  }
}

export const fetchMerchandisesSuccess = (merchandises) => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES_SUCCESS,
    payload: merchandises
  }
}

export const fetchMerchandisesError = () => {
  return {
    type: ActionTypes.FETCH_MERCHANDISES_ERROR,
  }
}

export const importMerchandises = (file) => {
  console.log(file);
  return {
    type: ActionTypes.IMPORT_MERCHANDISES,
    payload: file
  }
}

export const importMerchandisesSuccess = (merchandises) => {
  return {
    type: ActionTypes.IMPORT_MERCHANDISES_SUCCESS,
    payload: merchandises
  }
}

export const importMerchandisesError = (error) => {
  return {
    type: ActionTypes.IMPORT_MERCHANDISES_ERROR,
    payload: error
  }
}

export const editMerchandise = (query) => {
  return {
    type: ActionTypes.EDIT_MERCHANDISE,
    payload: query
  }
}

export const editMerchandiseSuccess = (merchandise) => {
  return {
    type: ActionTypes.EDIT_MERCHANDISE_SUCCESS,
    payload: merchandise
  }
}

export const editMerchandiseError = () => {
  return {
    type: ActionTypes.EDIT_MERCHANDISE_ERROR,
  }
}

export const addMerchandise = (merchandise) => {
  console.log(merchandise);
  return {
    type: ActionTypes.ADD_MERCHANDISE,
    payload: merchandise
  }
}

export const addMerchandiseSuccess = (merchandises) => {
  return {
    type: ActionTypes.ADD_MERCHANDISE_SUCCESS,
    payload: merchandises
  }
}

export const addMerchandiseError = (message) => {
  return {
    type: ActionTypes.ADD_MERCHANDISE_ERROR,
    payload: message
  }
}

export const updateMerchandise = (merchandise) => {
  console.log(merchandise);
  return {
    type: ActionTypes.UPDATE_MERCHANDISE,
    payload: merchandise
  }
}

export const updateMerchandiseSuccess = (merchandises) => {
  return {
    type: ActionTypes.UPDATE_MERCHANDISE_SUCCESS,
    payload: merchandises
  }
}

export const updateMerchandiseError = (message) => {
  return {
    type: ActionTypes.UPDATE_MERCHANDISE_ERROR,
    payload: message
  }
}

export const deleteMerchandise = (merchandise) => {
  console.log(merchandise);
  return {
    type: ActionTypes.DELETE_MERCHANDISE,
    payload: merchandise
  }
}

export const deleteMerchandiseSuccess = (merchandises) => {
  return {
    type: ActionTypes.DELETE_MERCHANDISE_SUCCESS,
    payload: merchandises
  }
}

export const deleteMerchandiseError = (message) => {
  return {
    type: ActionTypes.DELETE_MERCHANDISE_ERROR,
    payload: message
  }
}

export const getMerchandiseSetup = () => {
  return {
    type: ActionTypes.GET_MERCHANDISE_SETUP
  }
}

export const getMerchandiseSetupSuccess = (setup) => {
  return {
    type: ActionTypes.GET_MERCHANDISE_SETUP_SUCCESS,
    payload: setup
  }
}

export const getMerchandiseSetupError = () => {
  return {
    type: ActionTypes.GET_MERCHANDISE_SETUP_ERROR,
  }
}

export const resetMerchandise = () => {
  return {
    type: ActionTypes.RESET_MERCHANDISE
  }
}

export const openMerchandiseModal = (state) => {
  return {
    type: ActionTypes.OPEN_MERCHANDISE_MODAL,
    payload: state
  }
}

export const importInventory = (file) => {
  console.log(file);
  return {
    type: ActionTypes.IMPORT_INVENTORY,
    payload: file
  }
}

export const importInventorySuccess = (filelist) => {
  return {
    type: ActionTypes.IMPORT_INVENTORY_SUCCESS,
    payload: filelist
  }
}

export const importInventoryError = (error) => {
  return {
    type: ActionTypes.IMPORT_MERCHANDISES_ERROR,
    payload: error
  }
}

export const fetchInventoryImports = () => {
  return {
    type: ActionTypes.FETCH_INVENTORY_IMPORTS,
  }
}

export const fetchInventoryImportsSuccess = (filelist) => {
  return {
    type: ActionTypes.FETCH_INVENTORY_IMPORTS_SUCCESS,
    payload: filelist
  }
}

export const fetchInventoryImportsError = (error) => {
  return {
    type: ActionTypes.FETCH_INVENTORY_IMPORTS_ERROR,
    payload: error
  }
}

export const exportMerchandise = (params) => {
  return {
    type: ActionTypes.EXPORT_MERCHANDISE,
    payload: params
  }
}

export const exportMerchandiseSuccess = () => {
  return {
    type: ActionTypes.EXPORT_MERCHANDISE_SUCCESS,
  }
}

export const exportMerchandiseError = (error) => {
  return {
    type: ActionTypes.EXPORT_MERCHANDISE_ERROR,
    payload: error
  }
}

// // Passes
// export const fetchPasses = () => {
//   return {
//     type: ActionTypes.FETCH_PASSES
//   }
// }

// export const fetchPassesSuccess = (passes) => {
//   return {
//     type: ActionTypes.FETCH_PASSES_SUCCESS,
//     payload: passes
//   }
// }

// export const fetchPassesError = () => {
//   return {
//     type: ActionTypes.FETCH_PASSES_ERROR,
//   }
// }

// export const editPass = (query) => {
//   return {
//     type: ActionTypes.EDIT_PASS,
//     payload: query
//   }
// }

// export const editPassSuccess = (pass) => {
//   return {
//     type: ActionTypes.EDIT_PASS_SUCCESS,
//     payload: pass
//   }
// }

// export const editPassError = () => {
//   return {
//     type: ActionTypes.EDIT_PASS_ERROR,
//   }
// }

// export const addPass = (pass) => {
//   console.log(pass);
//   return {
//     type: ActionTypes.ADD_PASS,
//     payload: pass
//   }
// }

// export const addPassSuccess = (passes) => {
//   return {
//     type: ActionTypes.ADD_PASS_SUCCESS,
//     payload: passes
//   }
// }

// export const addPassError = (message) => {
//   return {
//     type: ActionTypes.ADD_PASS_ERROR,
//     payload: message
//   }
// }

// export const updatePass = (pass) => {
//   console.log(pass);
//   return {
//     type: ActionTypes.UPDATE_PASS,
//     payload: pass
//   }
// }

// export const updatePassSuccess = (passes) => {
//   return {
//     type: ActionTypes.UPDATE_PASS_SUCCESS,
//     payload: passes
//   }
// }

// export const updatePassError = (message) => {
//   return {
//     type: ActionTypes.UPDATE_PASS_ERROR,
//     payload: message
//   }
// }

// export const deletePass = (pass) => {
//   console.log(pass);
//   return {
//     type: ActionTypes.DELETE_PASS,
//     payload: pass
//   }
// }

// export const deletePassSuccess = (passes) => {
//   return {
//     type: ActionTypes.DELETE_PASS_SUCCESS,
//     payload: passes
//   }
// }

// export const deletePassError = (message) => {
//   return {
//     type: ActionTypes.DELETE_PASS_ERROR,
//     payload: message
//   }
// }

// export const getPassSetup = () => {
//   return {
//     type: ActionTypes.GET_PASS_SETUP
//   }
// }

// export const getPassSetupSuccess = (setup) => {
//   return {
//     type: ActionTypes.GET_PASS_SETUP_SUCCESS,
//     payload: setup
//   }
// }

// export const getPassSetupError = () => {
//   return {
//     type: ActionTypes.GET_PASS_SETUP_ERROR,
//   }
// }

// export const resetPass = () => {
//   return {
//     type: ActionTypes.RESET_PASS
//   }
// }

// export const openPassModal = (state) => {
//   return {
//     type: ActionTypes.OPEN_PASS_MODAL,
//     payload: state
//   }
// }

// Category
export const fetchCategories = () => {
  return {
    type: ActionTypes.FETCH_CATEGORIES
  }
}

export const fetchCategoriesSuccess = (categories) => {
  return {
    type: ActionTypes.FETCH_CATEGORIES_SUCCESS,
    payload: categories
  }
}

export const fetchCategoriesError = () => {
  return {
    type: ActionTypes.FETCH_CATEGORIES_ERROR,
  }
}

export const editCategory = (query) => {
  return {
    type: ActionTypes.EDIT_CATEGORY,
    payload: query
  }
}

export const editCategorySuccess = (merchandise_category) => {
  return {
    type: ActionTypes.EDIT_CATEGORY_SUCCESS,
    payload: merchandise_category
  }
}

export const editCategoryError = () => {
  return {
    type: ActionTypes.EDIT_CATEGORY_ERROR,
  }
}

export const addCategory = (merchandise_category) => {
  console.log(merchandise_category);
  return {
    type: ActionTypes.ADD_CATEGORY,
    payload: merchandise_category
  }
}

export const addCategorySuccess = (categories) => {
  return {
    type: ActionTypes.ADD_CATEGORY_SUCCESS,
    payload: categories
  }
}

export const addCategoryError = (message) => {
  return {
    type: ActionTypes.ADD_CATEGORY_ERROR,
    payload: message
  }
}

export const updateCategory = (merchandise_category) => {
  console.log(merchandise_category);
  return {
    type: ActionTypes.UPDATE_CATEGORY,
    payload: merchandise_category
  }
}

export const updateCategorySuccess = (categories) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY_SUCCESS,
    payload: categories
  }
}

export const updateCategoryError = (message) => {
  return {
    type: ActionTypes.UPDATE_CATEGORY_ERROR,
    payload: message
  }
}

export const deleteCategory = (merchandise_category) => {
  console.log(merchandise_category);
  return {
    type: ActionTypes.DELETE_CATEGORY,
    payload: merchandise_category
  }
}

export const deleteCategorySuccess = (categories) => {
  return {
    type: ActionTypes.DELETE_CATEGORY_SUCCESS,
    payload: categories
  }
}

export const deleteCategoryError = (message) => {
  return {
    type: ActionTypes.DELETE_CATEGORY_ERROR,
    payload: message
  }
}

export const resetCategory = () => {
  return {
    type: ActionTypes.RESET_CATEGORY
  }
}

export const openCategoryModal = (state) => {
  return {
    type: ActionTypes.OPEN_CATEGORY_MODAL,
    payload: state
  }
}
