import * as ActionTypes from '../../constants/ActionTypes';

export const fetchGyms = () => {
  return {
    type: ActionTypes.FETCH_GYMS
  }
}

export const fetchGymsSuccess = (gyms) => {
  return {
    type: ActionTypes.FETCH_GYMS_SUCCESS,
    payload: gyms
  }
}

export const fetchGymsError = (error) => {
  return {
    type: ActionTypes.FETCH_GYMS_ERROR,
    payload: error
  }
}

export const openGymModal = (state) => {
  return {
    type: ActionTypes.OPEN_GYM_MODAL,
    payload: state
  }
}

export const resetGym = () => {
  console.log('Reset Gym');
  return {
    type: ActionTypes.RESET_GYM
  }
}

export const addGym = (gym) => {
  return {
    type: ActionTypes.ADD_GYM,
    gym
  }
}

export const addGymSuccess = (gyms) => {
  return {
    type: ActionTypes.ADD_GYM_SUCCESS,
    payload: gyms
  }
}

export const addGymError = (error) => {
  return {
    type: ActionTypes.ADD_GYM_ERROR,
    payload: error
  }
}

export const editGym = (query) => {
  return {
    type: ActionTypes.EDIT_GYM,
    payload: query
  }
}

export const editGymSuccess = (gym) => {
  return {
    type: ActionTypes.EDIT_GYM_SUCCESS,
    payload: gym
  }
}

export const editGymError = (error) => {
  return {
    type: ActionTypes.EDIT_GYM_ERROR,
    payload: error
  }
}

export const updateGym = (gym) => {
  console.log(gym);
  return {
    type: ActionTypes.UPDATE_GYM,
    payload: gym
  }
}

export const updateGymSuccess = (gyms) => {
  return {
    type: ActionTypes.UPDATE_GYM_SUCCESS,
    payload: gyms
  }
}

export const updateGymError = (error) => {
  return {
    type: ActionTypes.UPDATE_GYM_ERROR,
    payload: error
  }
}

export const deleteGym = (gym) => {
  console.log(gym);
  return {
    type: ActionTypes.DELETE_GYM,
    payload: gym
  }
}

export const deleteGymSuccess = (gyms) => {
  return {
    type: ActionTypes.DELETE_GYM_SUCCESS,
    payload: gyms
  }
}

export const deleteGymError = (error) => {
  return {
    type: ActionTypes.DELETE_GYM_ERROR,
    payload: error
  }
}

export const fetchLocations = () => {
  return {
    type: ActionTypes.FETCH_LOCATIONS
  }
}

export const fetchLocationsSuccess = (locations) => {
  return {
    type: ActionTypes.FETCH_LOCATIONS_SUCCESS,
    payload: locations
  }
}

export const fetchLocationsError = (error) => {
  return {
    type: ActionTypes.FETCH_LOCATIONS_ERROR,
    payload: error
  }
}

export const updateLocation = (location) => {
  return {
    type: ActionTypes.UPDATE_LOCATION,
    payload: location
  }
}

export const updateLocationSuccess = (locations) => {
  return {
    type: ActionTypes.UPDATE_LOCATION_SUCCESS,
    payload: locations
  }
}

export const updateLocationError = (error) => {
  return {
    type: ActionTypes.UPDATE_LOCATION_ERROR,
    payload: error
  }
}

export const deleteLocation = (location) => {
  return {
    type: ActionTypes.DELETE_LOCATION,
    payload: location
  }
}

export const deleteLocationSuccess = (locations) => {
  return {
    type: ActionTypes.DELETE_LOCATION_SUCCESS,
    payload: locations
  }
}

export const deleteLocationError = (error) => {
  return {
    type: ActionTypes.DELETE_LOCATION_ERROR,
    payload: error
  }
}

export const fetchCountries = () => {
  return {
    type: ActionTypes.FETCH_COUNTRIES,
  }
}

export const fetchCountriesSuccess = (countries) => {
  return {
    type: ActionTypes.FETCH_COUNTRIES_SUCCESS,
    payload: countries
  }
}

export const requestOSS = () => {
  return {
    type: ActionTypes.GET_OSS_TOKEN
  }
}

export const getOSStokenSuccess = (cred) => {
  return {
    type: ActionTypes.GET_OSS_TOKEN_SUCCESS,
    payload: cred
  }
}
