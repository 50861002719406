import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const Users = ({match}) => (
  <Switch>
    <Route path={`${match.url}`} component={asyncComponent(() => import('../../components/Users/UserTable'))}/>
  </Switch>
);

export default Users;
