import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";
import axios from 'axios';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError } from "../actions/Common";
import { fetchBannersSuccess, addBannerSuccess, addBannerError, editBannerSuccess, editBannerError, updateBannerSuccess, updateBannerError, deleteBannerSuccess, deleteBannerError, updateBannerOrdersSuccess, updateBannerOrdersError, getOSStokenSuccess } from '../actions/Banners';

function* addBannerRequest({ banner }) {
  console.log('Add Banner called');
  try {
    const resp = yield call(axios.post, '/v1/admin/add_banner', banner);
    yield put(addBannerSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addBannerError(error.response));
  }
}

function* editBannerRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_banner', payload);
    yield put(editBannerSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editBannerError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateBannerRequest({ payload }) {
  console.log('Update Banner called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_banner', payload);
    console.log(resp);
    yield put(updateBannerSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateBannerError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteBannerRequest({ payload }) {
  console.log('Delete Banner called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_banner', payload);
    console.log(resp);
    yield put(deleteBannerSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteBannerError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchBannersRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/banners');
    yield put(fetchBannersSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

function* updateBannerOrdersRequest({ payload }) {
  console.log('Update Banner Orders called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_banner_orders', payload);
    console.log(resp);
    yield put(updateBannerOrdersSuccess());
  } catch (error) {
    console.log(error.response);
    yield put(updateBannerOrdersError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* getOSStokenRequest() {
  try {
    const resp = yield call(axios.post, '/v1/admin/request_oss_access_token');
    console.log(resp);
    yield put(getOSStokenSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response));
  }
}

export function* getOSStoken() {
  yield takeLatest(ActionTypes.GET_OSS_TOKEN, getOSStokenRequest);
}

export function* fetchBanners() {
  yield takeLatest(ActionTypes.FETCH_BANNERS, fetchBannersRequest);
}

export function* addBanner() {
  yield takeLatest(ActionTypes.ADD_BANNER, addBannerRequest);
}

export function* editBanner() {
  yield takeLatest(ActionTypes.EDIT_BANNER, editBannerRequest);
}

export function* updateBanner() {
  yield takeLatest(ActionTypes.UPDATE_BANNER, updateBannerRequest);
}

export function* updateBannerOrders() {
  yield takeLatest(ActionTypes.UPDATE_BANNER_ORDERS, updateBannerOrdersRequest);
}

export function* deleteBanner() {
  yield takeLatest(ActionTypes.DELETE_BANNER, deleteBannerRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchBanners),
    fork(addBanner),
    fork(editBanner),
    fork(updateBanner),
    fork(updateBannerOrders),
    fork(deleteBanner),
    fork(getOSStoken)
  ]);
}

