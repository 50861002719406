import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from 'axios';
import _ from 'lodash';
import * as ActionTypes from '../../constants/ActionTypes';
import { fetchError, showError } from "../actions/Common";
import { titleCase } from '../../util/common';
import {
  fetchPassesSuccess, addPassSuccess, addPassError, updatePassSuccess, updatePassError, deletePassSuccess, deletePassError, editPassSuccess, editPassError,
  fetchGroupsSuccess, addGroupSuccess, addGroupError, updateGroupSuccess, updateGroupError, deleteGroupSuccess, deleteGroupError, editGroupSuccess, editGroupError,
  exportPassSuccess, exportPassError, fetchPassCategoriesSuccess
} from '../actions/Pass';
import fileDownload from 'js-file-download';

// Pass
function* addPassRequest({ payload }) {
  console.log('Add Pass called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_pass', payload);
    console.log(resp);
    yield put(addPassSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addPassError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updatePassRequest({ payload }) {
  console.log('Update Pass called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_pass', payload);
    console.log(resp);
    yield put(updatePassSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updatePassError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deletePassRequest({ payload }) {
  console.log('Delete Pass called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_pass', payload);
    console.log(resp);
    yield put(deletePassSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deletePassError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchPassesRequest({ payload }) {
  console.log('fetchPassesRequest');

  let query = {}

  if (payload && (payload.length > 0)) {
    query = { keyword: payload }
  }

  try {
    const resp = yield call(axios.post, '/v1/admin/passes', query);
    yield put(fetchPassesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editPassRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_pass', payload);
    yield put(editPassSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editPassError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* exportPassRequest({ payload }) {
  console.log('Export Pass called', payload);
  try {
    const resp = yield call(axios.get, '/v1/admin/export_pass', {
      responseType: "arraybuffer",
      params: payload
    });

    if (payload.exportTarget && payload.exportTarget.includes('Download')) {
      fileDownload(resp.data, payload.filename);
    }
    yield put(exportPassSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(exportPassError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchPassCategoriesRequest() {
  try {
    const resp = yield call(axios.get, '/v1/fetch_categories');
    yield put(fetchPassCategoriesSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchPasses() {
  yield takeLatest(ActionTypes.FETCH_PASSES, fetchPassesRequest);
}

export function* addPass() {
  yield takeLatest(ActionTypes.ADD_PASS, addPassRequest);
}

export function* updatePass() {
  yield takeLatest(ActionTypes.UPDATE_PASS, updatePassRequest);
}

export function* deletePass() {
  yield takeLatest(ActionTypes.DELETE_PASS, deletePassRequest);
}

export function* editPass() {
  yield takeLatest(ActionTypes.EDIT_PASS, editPassRequest);
}

export function* exportPass() {
  yield takeLatest(ActionTypes.EXPORT_PASS, exportPassRequest);
}

// Group
function* addGroupRequest({ payload }) {
  console.log('Add Group called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/add_group', payload);
    console.log(resp);
    yield put(addGroupSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(addGroupError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* updateGroupRequest({ payload }) {
  console.log('Update Group called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/update_group', payload);
    console.log(resp);
    yield put(updateGroupSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(updateGroupError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* deleteGroupRequest({ payload }) {
  console.log('Delete Group called', payload);
  try {
    const resp = yield call(axios.post, '/v1/admin/delete_group', payload);
    console.log(resp);
    yield put(deleteGroupSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(deleteGroupError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* fetchGroupsRequest() {
  try {
    const resp = yield call(axios.get, '/v1/groups');
    yield put(fetchGroupsSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(fetchError(error.response ? error.response.data : 'Server not responding'));
  }
}

function* editGroupRequest({ payload }) {
  try {
    const resp = yield call(axios.post, '/v1/admin/get_group', payload);
    yield put(editGroupSuccess(resp.data));
  } catch (error) {
    console.log(error.response);
    yield put(editGroupError(error.response ? error.response.data : 'Server not responding'));
  }
}

export function* fetchGroups() {
  yield takeLatest(ActionTypes.FETCH_GROUPS, fetchGroupsRequest);
}

export function* addGroup() {
  yield takeLatest(ActionTypes.ADD_GROUP, addGroupRequest);
}

export function* updateGroup() {
  yield takeLatest(ActionTypes.UPDATE_GROUP, updateGroupRequest);
}

export function* deleteGroup() {
  yield takeLatest(ActionTypes.DELETE_GROUP, deleteGroupRequest);
}

export function* editGroup() {
  yield takeLatest(ActionTypes.EDIT_GROUP, editGroupRequest);
}

export function* fetchPassCategories() {
  yield takeLatest(ActionTypes.FETCH_PASS_CATEGORIES, fetchPassCategoriesRequest);
}

export default function* rootSaga() {
  yield all([
    fork(fetchPasses),
    fork(addPass),
    fork(updatePass),
    fork(deletePass),
    fork(editPass),
    fork(fetchGroups),
    fork(addGroup),
    fork(updateGroup),
    fork(deleteGroup),
    fork(editGroup),
    fork(exportPass),
    fork(fetchPassCategories)
  ]);
}
